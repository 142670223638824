import React from 'react';
import '../CSS/Experience.css';
import ProfilePhoto from '../Components/AboutMe/ProfilePhoto';
import TechStack from '../Components/AboutMe/TechStack';
import Title from '../Components/Shared/Title';
import Info from "../Components/AboutMe/Info";
import Intro from "../Components/AboutMe/Intro";
import { Container, Row } from "react-bootstrap";

const AboutMe = () => {
    document.title = "About Me";

    const techStack = [
        {
            "id": 1,
            "description": "Programming Languages",
            items: [
                "Dart",
                "TypeScript",
                "JavaScript",
                "Java",
                "Python"
            ]
        },
        {
            "id": 2,
            "description": "Frameworks",
            items: [
                "Flutter",
                "React",
                "Bootstrap",
                "Express.js",
                "Flask",
                "AWS Chalice"
            ]
        },
        {
            "id": 3,
            "description": "Technologies",
            items: [
                "HTML",
                "CSS",
                "Redux",
                "Redux Toolkit",
                "Node.js",
                "AWS (DynamoDB, Lambda, S3)",
                "SCSS",
                "NoSQL",
                "Git",
                "Figma",
            ]
        },

    ];

    const personalInfoData = {
        location: "Atlanta, GA",
        position: "Software Developer",
        companyName: "RoadSync"
    }

    const introData = {
        description1: "Hi there! I’m a software developer that enjoys building things! I have built mobile applications, web applications, and websites!",
        description2: "In my free time, I enjoy playing tennis, working out, running, playing board games, reading, and personal finance!"
    }

    return (
        <Container className="p-3">
            <Row className="d-flex flex-column justify-content-center text-center align-items-center">
                <Title title="About Me" />
                <ProfilePhoto />
                <Info personalInfo={personalInfoData} />
                <Container>
                    <Row className="flex-column">
                        <TechStack techStack={techStack} />
                    </Row>
                </Container>
                <Intro introData={introData} />
            </Row>

        </Container>
    )
}

export default AboutMe;