import React from 'react';
import { Col } from "react-bootstrap";
import Card from 'react-bootstrap/Card';

const Info = (props) => {
    const { location, companyName, position } = props.personalInfo;


    return (

        <Col xs={12} md={6} className="my-3">
            <Card>
                <Card.Body>
                    <Card.Title className="text-center">
                        <img className="mr-3" src="./Images/AboutMe/location.png" />
                                    Work in {location}
                            </Card.Title>
                    <Card.Title className="text-center">
                        <img className="mr-3" src="./Images/AboutMe/position.png" />
                    {position} at {companyName}
                            </Card.Title>
                    <Card.Text>
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
    )
}

export default Info;