import React from 'react';
import '../../CSS/AboutMe.css';
import '../../CSS/TechStack.css';
import { Col } from "react-bootstrap";
import Card from 'react-bootstrap/Card';
const TechStack = (props) => {


    const { techStack } = props;


    return (
        techStack.map((data) => {
            return (
              
                        <Col className="mt-4 text-left mx-auto" xs={12} md = {6}>
                            <Card>
                                <Card.Body>
                                    <Card.Title className="text-center about-title">
                                        {data.description}
                                    </Card.Title>
                                    <Card.Text>
                                        {data.items.map((item) => {
                                            return (
                                                <div className="text-center tech-font">
                                                    {item}
                                                </div>
                                            )
                                        })}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                 
            )
        })
    )
}

export default TechStack;