

import React from 'react';
import Card from 'react-bootstrap/Card';
import '../../CSS/Title.css';


const title = (props) => {
    const { title } = props;
    return (
        <div className = "title">
            {title}
        </div>
    )
}


export default title;