import React from 'react';
import '../CSS/Experience.css';
import ProjectCard from '../Components/Projects/project-card';
import Title from '../Components/Shared/Title';
import { Container, Row } from "react-bootstrap";


const Projects = () => {
    document.title = "Projects";

    let projectData = [
        {
            "id": 1,
            "Name": "SELC Gamification System",
            "ShortDescription": "Full-Stack Web Application",
            "Description": "Developed full-stack web application to streamline workshop registration process and increase participation in online events by gamifying events and was used by approximately 180 users.",
            "LiveDemoLink": "https://selc-gamification-system.onrender.com",
            "GithubLink": "https://github.com/AndyUGA/SELC-Gamification-System",
            "Technologies": [
                "HTML",
                "CSS",
                "SCSS",
                "JavaScript",
                "Bootstrap 4",
                "Google Firebase",
                "Node.js",
                "Express.js",
                "EJS"
            ]
        },
        {
            "id": 2,
            "Name": "Invisarmor",
            "ShortDescription": "Shopify Website",
            "Description": "Developed a custom website that integrated with shopify for a seamless shopping experience.",
            "LiveDemoLink": "https://invisarmor.netlify.app/",
            "Technologies": [
                "HTML",
                "SCSS",
                "CSS",
                "JavaScript",
                "Bootstrap 4",
            ]
        },
        {
            "id": 3,
            "Name": "UGA Vietnamese Student Association",
            "ShortDescription": "Website",
            "Description": "Developed website to showcase a college organization and grow their online presence.",
            "LiveDemoLink": "https://ugavsa.netlify.app/",
            "Technologies": [
                "HTML",
                "CSS",
                "JavaScript",
                "Bootstrap 4",
            ]
        },

        {
            "id": 4,
            "Name": "Leaderboard System",
            "ShortDescription": "Full-Stack Web Application",
            "Description": "Developed a web application that allowed administrators to track points earned by participants and allowed participants to view their individual and team points!",
            "LiveDemoLink": "https://leaderboard-system.onrender.com",
            "GithubLink": "https://github.com/AndyUGA/UVSASE-Gameday-Leaderboards",
            "Technologies": [
                "HTML",
                "CSS",
                "JavaScript",
                "Bootstrap 4",
                "MongoDB",
                "Node.js",
                "EJS"
            ]
        },
        {
            "id": 5,
            "Name": "Point System",
            "ShortDescription": "Full-Stack Web Application",
            "Description": "Developed a web application that was used to help assist in the gamification process of a leadership conference. This web app assisted with scanning QR codes and assigning points to attenddees. It also allowed users to view a leaderboard to see where they stood in the rankings.", "GithubLink": "https://github.com/AndyUGA/Element",
            "Technologies": [
                "HTML",
                "CSS",
                "JavaScript",
                "Bootstrap 4",
                "MongoDB",
                "Node.js",
                "EJS"
            ]
        },
        {
            "id": 6,
            "Name": "Registration System",
            "ShortDescription": "Full-Stack Web Application",
            "Description": "Developed a web application to streamline registration process and reduce registration process time.",
            "GithubLink": "https://github.com/AndyUGA/UVSASE-Registration-System",
            "Technologies": [
                "HTML",
                "CSS",
                "JavaScript",
                "Bootstrap 4",
                "MongoDB",
                "Node.js",
                "EJS"
            ]
        },
        {
            "id": 7,
            "Name": "Vincent Real Estate",
            "ShortDescription": "Online Application Website",
            "Description": "Developed web application for users to submit a rental application form, pay application fees, and submit request repairs for the Vincnet Real Esate Company.",
            "LiveDemoLink": "https://www.vincentres.com/",
            "Technologies": [
                "HTML",
                "CSS",
                "JavaScript",
                "Bootstrap 4",
                "Node.js",
                "Handlebars"
            ]
        },
        {
            "id": 8,
            "Name": "Book Note Tracker",
            "ShortDescription": "Full-Stack Web Application",
            "Description": "Developed a web application that allows user to record notes of books they are currently reading.",
            "GithubLink": "https://github.com/AndyUGA/Book-Note-Tracker",
            "Technologies": [
                "HTML",
                "CSS",
                "JavaScript",
                "Bootstrap 4",
                "MongoDB",
                "Passport.js",
                "Node.js",
                "EJS"
            ]
        },
        {
            "id": 9,
            "Name": "Reddit Clone",
            "ShortDescription": "Single-Page Application",
            "Description": "Developed a web application that mimics reddit.com using their API.",
            "LiveDemoLink": "https://myreddit.netlify.com/",
            "GithubLink": "https://github.com/AndyUGA/RedditClone",
            "Technologies": [
                "HTML",
                "CSS",
                "JavaScript",
                "Angular JS",
                "Reddit API",
                "Bootstrap 4",
            ]
        },

    ];

    return (
        <Container className="p-3">
            <Row className="d-flex justify-content-center text-center">
                <Title title="Projects" />
            </Row>
            <ProjectCard data={projectData} />
        </Container>
    )
}

export default Projects;