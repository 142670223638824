import React from 'react';
import '../../CSS/project-card.css';

import { Col, Row } from "react-bootstrap";

const ProjectCard = (props) => {
    const { data } = props;

    return (
        data.map((item) => {
            return (
                <div className="card my-3">
                    <Row className="my-5 d-flex align-items-center">
                        <Col md={2} className="d-flex justify-content-center" >
                            <img src={`./Images/Projects/${item.Name}.png`} className="img-fluid project-image ml-3" />
                        </Col>
                        <Col md={10}>
                            <div className="project-name text-center text-md-left">
                                {item.Name}
                            </div>
                            <div className="my-3 mx-3 mx-md-0">
                                {item.Description}
                            </div>
                            <Row className="d-flex justify-content-center justify-content-md-start mt-3">
                                {item.LiveDemoLink ? <Col sm={12} md="auto" className="shadow-lg mb-4 mb-md-0 text-center demoLink project-link">
                                    <a className="text-white " href={item.LiveDemoLink} target="_blank">
                                        Live Demo
                                        <img className="externalLinkPadding" src="./Images/Projects/externalLink.png" />
                                    </a>
                                </Col> : ""}
                                {item.GithubLink ? <Col sm={12} md="auto" className="shadow-lg mb-4 mb-md-0 mx-md-3 text-center project-link">
                                    <a href={item.GithubLink} target="_blank" className="viewSourceText">
                                        View Source
                                        <img className="externalLinkPadding" src="./Images/Projects/github.png" />
                                    </a>
                                </Col> : ""}
                            </Row>
                        </Col>
                    </Row>
                </div>
            )




        })
    )

}

export default ProjectCard