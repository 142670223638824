import React from 'react';
import '../CSS/Experience.css';
import Projects from '../Components/Freelance/projects';
import Title from '../Components/Shared/Title';

import { Container, Col, Row, Form } from "react-bootstrap";


const Freelance = () => {

    let freelanceData = [
        {
            "Company": "UGA Vietnamese Student Association",
            "Link": "https://ugavsa.netlify.app/"
        },
        {
            "Company": "Home Value Promise",
            "Link": "https://homevaluepromise-realestate.netlify.app/"
        },
        {

            "Company": "Big Bon",
            "Link": "https://andytruongproject1.netlify.app/"
        },
        {

            "Company": "Peach State Office Furniture Store",
            "Link": "https://psofficefurniture.com/"
        },
        {

            "Company": "Vincent Real Estate",
            "Link": "https://www.vincentres.com/"
        },

        {

            "Company": "Invisarmor",
            "Link": "https://invisarmor.netlify.app/"
        },

        {

            "Company": "UGA Chinese Culture and Language Association",
            "Link": "https://ccla.netlify.app/"
        },

        {

            "Company": "Siri Thai Cuisine",
            "Link": "http://www.sirithaiathens.com/"
        },
    ];

    return (
        <Container className="p-3">
            <Row className="d-flex justify-content-center">
                <Title title="Freelance Work" />
            </Row>
            <Row className="p-3">
                <Projects data={freelanceData} />
            </Row>
        </Container>
    )
}

export default Freelance;