import React from 'react';
import './CSS/App.css';
import Home from './Routes/Home';
import Navbar from './Components/Shared/Navbar';
import Experience from './Routes/Experience';
import AboutMe from './Routes/AboutMe';
import Freelance from './Routes/Freelance';
import Freelance_website from './Routes/Freelance-website';
import Projects from './Routes/Projects';


import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

function App() {
  return (
    <Router>
      <Navbar />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/aboutme">
          <AboutMe />
        </Route>
        <Route path="/experience">
          <Experience />
        </Route>
        <Route path="/freelance">
          <Freelance_website />
        </Route>
        <Route path="/projects">
          <Projects />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
