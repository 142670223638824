import React from 'react';
import { Col } from "react-bootstrap";
import Card from 'react-bootstrap/Card';

const Intro = (props) => {
    const { description1, description2 } = props.introData;


    return (

        <Col className="mt-4 text-left mx-auto" xs={12} md={6}>
        <Card>
            <Card.Body>
                <Card.Title>
                    <div>
                       {description1}
                    </div>
                    <br></br>
                    <div>
                    {description2}
                    </div>
                </Card.Title>
                <Card.Text>
                </Card.Text>
            </Card.Body>
        </Card>
    </Col>
    )
}

export default Intro;