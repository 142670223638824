import React from 'react';
import '../../CSS/Background.css'
import Button from 'react-bootstrap/Button';
import { Col, Row, Form } from "react-bootstrap";


const ButtonLinks = (props) => {

    let { data } = props;

    return (
        data.map((item) => {
            return (
                <Col xs={12} sm={3}>
                    <Button className={item.id !== 1 ? "mt-3 mt-sm-0" : ""} variant="outline-light" target="_blank" href={item.link}>
                        {item.source}
                    </Button>
                </Col>
            )
        })
    )

}

export default ButtonLinks;