import React from 'react';
import { Navbar, NavDropdown } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import '../../CSS/Navbar.css';

const NavbarComponent = () => {

    return (
        <Navbar collapseOnSelect bg="dark" variant="dark" expand="lg">
            <Navbar.Brand href="/">
                Home
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">

                    <Nav.Link href="/" as={Link} className="nav-link link-items" to="/aboutme">
                        About Me
                    </Nav.Link>

                    <Nav.Link href="/" as={Link} className="nav-link link-items" to="/experience">
                        Experience
                    </Nav.Link>

                    <Nav.Link href="/" as={Link} className="nav-link link-items" to="/projects">
                        Projects
                    </Nav.Link>

                    <NavDropdown className="ml-0 ml-lg-2" title="Links" id="collasible-nav-dropdown">
                        <Nav.Link className="pl-4 navbar-link-text" target="_blank" href="https://drive.google.com/file/d/1SEj507I-ceE9a4llT0IkPNYv-oZbqeQF/view?usp=sharing">
                            <img className="navbar-icons" src="./Images/Navbar/Resume.png" />
                            Resume
                        </Nav.Link>
                        <Nav.Link className="pl-4 navbar-link-text" target="_blank" href="https://github.com/AndyUGA">
                            <img className="navbar-icons" src="./Images/Navbar/GitHub2.png" />
                            GitHub
                        </Nav.Link>
                        <Nav.Link className="pl-4 navbar-link-text" target="_blank" href="https://www.linkedin.com/in/andy-truong/">
                            <img className="navbar-icons" src="./Images/Navbar/LinkedIn.png" />
                            LinkedIn
                        </Nav.Link>
                        <Nav.Link className="pl-4 navbar-link-text" target="_blank" href="https://andytruongcs.medium.com/">
                            <img className="navbar-icons" src="./Images/Navbar/Medium.png" />
                            Medium
                        </Nav.Link>

                    </NavDropdown>

                </Nav>

            </Navbar.Collapse>
        </Navbar>
    )

}


export default NavbarComponent;