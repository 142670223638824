import React from 'react';
import '../../CSS/AboutMe.css';


const ProfilePhoto = () => {
    
    return (
        <img src = "./Images/AboutMe/Profile.png" className = "profilePhoto" /> 
    )
}

export default ProfilePhoto;