import React from 'react';
import '../CSS/Experience.css';
import InfoCard from '../Components/Experience/info-card';
import Title from '../Components/Shared/Title';
import { Container, Col, Row, Form } from "react-bootstrap";


const Experience = () => {
    document.title = "Experience";

    let experienceData = [
        {
            "id": 1,
            "Position": "Software Developer",
            "Company": "RoadSync (Series B Startup)",
            "Timeline": "October 2021 - Present (2 Years)",
            Responsibilities: [
                "Develop and maintain iOS, Android, and Web apps using Flutter and Dart with approximately 40,000 users",
                "Implement highly promoted front-end features and bug fixes using test driven development (TDD)",
                "Led front-end and back-end efforts to manage media uploads using Flask, AWS SAM, S3 and DynamoDB",
                "Implement Plaid integration and built 3 related frontend components increasing revenue by 5%",
                "Develop digital wallet UI to enable reuse of credit cards decreasing invoice completion time by 25%",
                "Built UI for passwordless authentication using AWS Cognito reducing incorrect login attempts by 20%",
                "Manage app store deployments using CodeMagic, TestFlight, App Store Connect and Google Play Console",
                "Create 8 detailed internal documentation resources using Notion and Confluence reducing engineer debugging time by 10%",
                "Integrate 3rd party package to secure financial data using Swift, Kotlin, and Flutter Method Channels",
                "Migrate React app to Flutter enabling deployment as both a web and mobile app from a single codebase",
                "Utilize Typescript, React, and Redux Toolkit to develop new UI features increasing user retention by 15%",
                "Use AWS Chalice and Python to implement and rigorously test serverless APIs serving ~5000 daily requests",
                "Implement both frontend and backend requirements for highly requested features leading to 20% reduction",
            ]
        },
        {
            "id": 2,
            "Position": "Application Developer",
            "Company": "Automatic Data Processing",
            "Timeline": "August 2019 - October 2021 (2 Years)",
            Responsibilities: [
                "Increase user retention by 20% by leading development effort to refactor 7 non-responsive web pages to be fully mobile responsive",
                "Reduce development time by 30% by developing reusable components for responsive work utilizing HTML5, SCSS, Bootstrap 4, Flexbox, JavaScript, React, and jQuery",
                "Assist migrating existing service to serverless architecture utilizing AWS Lambdas and serverless offline plugin for local development and testing",
                "Work includes: ADP Marketplace Store Homepage, App Overview, App Features, App Reviews, App Editions & Pricing and App Support & Terms",
                "Provide technical and professional mentorship to 3 application developers in weekly 1-on-1 mentor meetings",
            ]
        },
        {
            "id": 3,
            "Position": "Web Application Developer",
            "Company": "UVSASE",
            "Timeline": "November 2018 - July 2019",
            Responsibilities: [
                "Reduce tech costs by ~1.2K by developing a custom web application to replace formerly used software",
                "Increase user traffic by 30% by overhauling and improving visual appeal of existing website",
                "Routinely deliver 100% of deliverables ahead of time by directing and managing 3 technology associates"
            ]
        },
        {
            "id": 4,
            "Position": "Freelance Web Developer",
            "Timeline": "August 2018 - August 2019",
            Responsibilities: [
                "Consult with prospective clients to gather technical requirements and develop functional final product",
                "Clients include: Invisarmor, UGA VSA, UVSASE , Vincent Real Estate, CCLA, SiriThai, and KoaBowls",
            ]
        },
        {
            "id": 5,
            "Position": "Software Developer (Part-time)",
            "Company": "Unclaimed Property Consulting and Reporting",
            "Timeline": "December 2018 - Feb 2019",
            Responsibilities: [
                "Cut-down process time by 40% by developing python script to automate manual encryption process",
                "Implemented new features for mobile app to improve overall efficiency and experience",
            ]
        },
        {
            "id": 6,
            "Position": "Mobile App Developer Intern",
            "Company": "Unclaimed Property Consulting and Reporting",
            "Timeline": "May 2018 - August 2018",
            Responsibilities: [
                "Reduced time required by 80% and manual mistakes by 95% by building cross-platform mobile application to automate repetitive tasks",
                "Utilized Angular 4, TypeScript, Ionic 3 Framework, HTML5, and CSS3",
                "Coordinated with CTO in weekly one-on-one meetings to develop improvements and debug major issues",
                "Published app to iOS App Store and Google Play Store"
            ]
        },

    ];

    return (
        <Container className="p-3">
            <Row className="d-flex justify-content-center text-center">
                <Title title="Work Experience" />
            </Row>
            <Row>
                <InfoCard data={experienceData} />
            </Row>
        </Container>
    )
}

export default Experience;