import React from 'react';
import '../CSS/Background.css'
import { Row } from "react-bootstrap";
import ButtonLinks from '../Components/Home/Button-Links';

const Home = () => {

    let buttonLinksData = [
        {
            "id": 1,
            "source": "GitHub",
            "link": "https://github.com/AndyUGA"
        },
        {
            "id": 2,
            "source": "LinkedIn",
            "link": "https://www.linkedin.com/in/andy-truong/"
        },
        {
            "id": 3,
            "source": "Resume",
            "link": "https://drive.google.com/file/d/1SEj507I-ceE9a4llT0IkPNYv-oZbqeQF/view?usp=sharing"
        },
        {
            "id": 4,
            "source": "YouTube",
            "link": "https://www.youtube.com/c/andystechtutorials"
        },
    ]

    return (
        <div className="backgroundImage">
            <div className="landingPageContainer">
                <div className="name">
                    Andy Truong
                </div>
                <div className="container justify-content-center d-flex text-center">
                    <Row>

                        <ButtonLinks data={buttonLinksData} />

                    </Row>
                </div>
            </div>
        </div>
    )
}

export default Home;