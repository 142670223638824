import React from 'react';
import Card from 'react-bootstrap/Card';
import '../../CSS/info-card.css';


const InfoCard = (props) => {

    let { data } = props;

    return (

        data.map((item) => {
            return (
                <Card className="my-3 mx-3">
                    <Card.Body>
                        <Card.Title className="card-heading">
                            {item.Position} {item.Company ? `| ${item.Company}` : ""}
                        </Card.Title>
                        <Card.Text className="timeline">
                            {item.Timeline}
                        </Card.Text>
                        <Card.Text>
                            {item.Responsibilities.map((item) => {
                                return <div>
                                    -  {item}
                                </div>
                            })}
                        </Card.Text>
                    </Card.Body>
                </Card>

            )
        }
        )

    )
}

export default InfoCard;