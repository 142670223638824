import React from 'react';
import '../../CSS/Projects.css';
import Card from 'react-bootstrap/Card';

const Projects = (props) => {

    const { data } = props;

    return (
        data.map((item) => {
            return (
                <Card className="my-4">
                    <Card.Body>
                        <a className="freelance-link" target="_blank" href={item.Link}>
                            <Card.Title className="freelance-title">
                                {item.Company}
                            </Card.Title>
                            <img className="img-fluid" src={`./Images/Freelance/${item.Company}.png`} />
                        </a>
                    </Card.Body>

                </Card>
            )
        })
    )
}


export default Projects;